window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.email_input = window.locales.am.email_input || {};window.locales.am.email_input.email_validation = window.locales.am.email_input.email_validation || {};window.locales.am.email_input.email_validation = {...window.locales.am.email_input.email_validation, ...{
    "please_use_company_email": "እባክዎ የኩባንያዎን ኢሜይል አድራሻ ይጠቀሙ",           
    "please_enter_valid_email": "እባክዎ ትክክለኛ ኢሜይል አድራሻ ያስገቡ",           
    "please_enter_matching_domain": "እባከዎ በ @{{requireDomain}} ጎራ ኢሜይል አድራሻ ያስገቡ",           
    "please_use_non_relay_email": "እባክዎ የማይተላለፍ የኢሜይል አድራሻ ይጠቀሙ"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.email_input = window.locales.ar.email_input || {};window.locales.ar.email_input.email_validation = window.locales.ar.email_input.email_validation || {};window.locales.ar.email_input.email_validation = {...window.locales.ar.email_input.email_validation, ...{
    "please_use_company_email": "يرجى استخدام عنوان البريد الإلكتروني لشركتك",           
    "please_enter_valid_email": "يرجى إدخال بريد إلكتروني صحيح",           
    "please_enter_matching_domain": "الرجاء إدخال عنوان بريد إلكتروني باستخدام النطاق @{{requireDomain}}",
    "please_use_non_relay_email": "يرجى استخدام عنوان بريد إلكتروني غير مرحل"
}
};window.locales.en = window.locales.en || {};window.locales.en.email_input = window.locales.en.email_input || {};window.locales.en.email_input.email_validation = window.locales.en.email_input.email_validation || {};window.locales.en.email_input.email_validation = {...window.locales.en.email_input.email_validation, ...{
    "please_use_company_email": "Please use your company email address",
    "please_enter_valid_email": "Please enter a valid email address",
    "please_enter_matching_domain": "Please enter an email address with the domain @{{requireDomain}}",
    "please_use_non_relay_email": "Please use a non-relayed email address"
}
};window.locales.es = window.locales.es || {};window.locales.es.email_input = window.locales.es.email_input || {};window.locales.es.email_input.email_validation = window.locales.es.email_input.email_validation || {};window.locales.es.email_input.email_validation = {...window.locales.es.email_input.email_validation, ...{
    "please_use_company_email": "Por favor, utilice la dirección de correo electrónico de su empresa",           
    "please_enter_valid_email": "Por favor, introduzca una dirección de correo electrónico válida",           
    "please_enter_matching_domain": "Ingrese una dirección de correo electrónico con el dominio @{{requireDomain}}",
    "please_use_non_relay_email": "Utilice una dirección de correo electrónico no retransmitida"
}
};window.locales.it = window.locales.it || {};window.locales.it.email_input = window.locales.it.email_input || {};window.locales.it.email_input.email_validation = window.locales.it.email_input.email_validation || {};window.locales.it.email_input.email_validation = {...window.locales.it.email_input.email_validation, ...{
    "please_use_company_email": "Per favore, usa l'indirizzo email della tua azienda",           
    "please_enter_valid_email": "Per favore, inserisci un indirizzo email valido",           
    "please_enter_matching_domain": "Inserisci un indirizzo email con il dominio @{{requireDomain}}",
    "please_use_non_relay_email": "Si prega di utilizzare un indirizzo e-mail non inoltrato"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.email_input = window.locales.zh.email_input || {};window.locales.zh.email_input.email_validation = window.locales.zh.email_input.email_validation || {};window.locales.zh.email_input.email_validation = {...window.locales.zh.email_input.email_validation, ...{
    "please_use_company_email": "请使用您的公司电子邮箱",           
    "please_enter_valid_email": "请输入有效的电子邮箱",           
    "please_enter_matching_domain": "请输入带有域名@{{requireDomain}}的电子邮箱地址",           
    "please_use_non_relay_email": "请使用非转发的电子邮箱地址"           
}
};