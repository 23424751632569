window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.exec_ed_eligibility = window.locales.am.exec_ed_eligibility || {};window.locales.am.exec_ed_eligibility.cert_acceptance_modal = window.locales.am.exec_ed_eligibility.cert_acceptance_modal || {};window.locales.am.exec_ed_eligibility.cert_acceptance_modal = {...window.locales.am.exec_ed_eligibility.cert_acceptance_modal, ...{
    "congratulations": "እንኳን ደስ አላችሁ",
    "you_are_eligible_to_enroll": "በእነዚህ የአስፈፃሚ ትምህርት የምስክር ወረቀቶች ለመመዝገብ ብቁ ነዎት።",
    "heading_ai_and_future_of_business": "AI እና የወደፊት የንግድ ሥራ (2 ወራት)",
    "heading_data_and_finance": "ውሂብ እና ፋይናንስ (3-4 ወራት)",
    "heading_rise_to_c_suite": "ወደ C-Suite መውጣት (8 ወራት)",
    "review_and_register": "ይገምግሙ እና ይመዝገቡ",
    "learn_more": "ተጨማሪ እወቅ",
    "recommended": "የሚመከር",
    "also_available": "እንዲሁም ይገኛል።"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.exec_ed_eligibility = window.locales.ar.exec_ed_eligibility || {};window.locales.ar.exec_ed_eligibility.cert_acceptance_modal = window.locales.ar.exec_ed_eligibility.cert_acceptance_modal || {};window.locales.ar.exec_ed_eligibility.cert_acceptance_modal = {...window.locales.ar.exec_ed_eligibility.cert_acceptance_modal, ...{
    "congratulations": "تهانينا",
    "you_are_eligible_to_enroll": "أنت مؤهل للتسجيل في شهادات التعليم التنفيذي هذه.",
    "heading_ai_and_future_of_business": "الذكاء الاصطناعي ومستقبل الأعمال (شهران)",
    "heading_data_and_finance": "البيانات والتمويل (3-4 أشهر)",
    "heading_rise_to_c_suite": "الارتقاء إلى C-Suite (8 أشهر)",
    "review_and_register": "المراجعة والتسجيل",
    "learn_more": "يتعلم أكثر",
    "recommended": "مُستَحسَن",
    "also_available": "أيضا متاح"
}
};window.locales.en = window.locales.en || {};window.locales.en.exec_ed_eligibility = window.locales.en.exec_ed_eligibility || {};window.locales.en.exec_ed_eligibility.cert_acceptance_modal = window.locales.en.exec_ed_eligibility.cert_acceptance_modal || {};window.locales.en.exec_ed_eligibility.cert_acceptance_modal = {...window.locales.en.exec_ed_eligibility.cert_acceptance_modal, ...{
    "congratulations": "Congratulations",
    "you_are_eligible_to_enroll": "You are eligible to enroll in these Executive Education certificates.",
    "heading_ai_and_future_of_business": "AI & Future of Business (2 months)",
    "heading_data_and_finance": "Data & Finance (3-4 months)",
    "heading_rise_to_c_suite": "Rise to the C-Suite (8 months)",
    "review_and_register": "Review and Register",
    "learn_more": "Learn More",
    "recommended": "Recommended",
    "also_available": "Also Available"
}
};window.locales.es = window.locales.es || {};window.locales.es.exec_ed_eligibility = window.locales.es.exec_ed_eligibility || {};window.locales.es.exec_ed_eligibility.cert_acceptance_modal = window.locales.es.exec_ed_eligibility.cert_acceptance_modal || {};window.locales.es.exec_ed_eligibility.cert_acceptance_modal = {...window.locales.es.exec_ed_eligibility.cert_acceptance_modal, ...{
    "congratulations": "Felicidades",
    "you_are_eligible_to_enroll": "Usted es elegible para inscribirse en estos certificados de Educación Ejecutiva.",
    "heading_ai_and_future_of_business": "IA y futuro de los negocios (2 meses)",
    "heading_data_and_finance": "Datos y finanzas (3-4 meses)",
    "heading_rise_to_c_suite": "Ascenso a la C-Suite (8 meses)",
    "review_and_register": "Revisar y registrarse",
    "learn_more": "Aprende más",
    "recommended": "Recomendado",
    "also_available": "También disponible"
}
};window.locales.it = window.locales.it || {};window.locales.it.exec_ed_eligibility = window.locales.it.exec_ed_eligibility || {};window.locales.it.exec_ed_eligibility.cert_acceptance_modal = window.locales.it.exec_ed_eligibility.cert_acceptance_modal || {};window.locales.it.exec_ed_eligibility.cert_acceptance_modal = {...window.locales.it.exec_ed_eligibility.cert_acceptance_modal, ...{
    "congratulations": "Congratulazioni",
    "you_are_eligible_to_enroll": "Hai diritto a iscriverti a questi certificati di formazione executive.",
    "heading_ai_and_future_of_business": "AI e futuro del business (2 mesi)",
    "heading_data_and_finance": "Dati e finanza (3-4 mesi)",
    "heading_rise_to_c_suite": "Ascesa alla C-Suite (8 mesi)",
    "review_and_register": "Controlla e registrati",
    "learn_more": "Saperne di più",
    "recommended": "Consigliato",
    "also_available": "Anche disponibile"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.exec_ed_eligibility = window.locales.zh.exec_ed_eligibility || {};window.locales.zh.exec_ed_eligibility.cert_acceptance_modal = window.locales.zh.exec_ed_eligibility.cert_acceptance_modal || {};window.locales.zh.exec_ed_eligibility.cert_acceptance_modal = {...window.locales.zh.exec_ed_eligibility.cert_acceptance_modal, ...{
    "congratulations": "恭喜你",
    "you_are_eligible_to_enroll": "您有资格参加这些高管教育证书。",
    "heading_ai_and_future_of_business": "人工智能与商业的未来（2 个月）",
    "heading_data_and_finance": "数据与财务（3-4 个月）",
    "heading_rise_to_c_suite": "晋升最高管理层（8 个月）",
    "review_and_register": "审核并注册",
    "learn_more": "了解更多",
    "recommended": "受到推崇的",
    "also_available": "也提供"
}
};