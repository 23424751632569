window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.dialog_modals = window.locales.am.dialog_modals || {};window.locales.am.dialog_modals.dialog_modal_alert = window.locales.am.dialog_modals.dialog_modal_alert || {};window.locales.am.dialog_modals.dialog_modal_alert = {...window.locales.am.dialog_modals.dialog_modal_alert, ...{
    "confirm_action_ok": "እሺ",           
    "confirm_action_cancel": "ሰርዝ",           
    "confirm_thanks": "ስላሳወቁን እናመሰግናለን።",           
    "confirm_delete_with_typing_body": "እርግጠኛ ነዎት መሰረዝ ይፈልጋሉ<span class=\"thing\">{{thing}}</span>? ለማረጋገጥ ከታች <br><br>ይተይቡ<strong>{{confirmationText}}</strong>።",           
    "confirm_action_delete": "አጥፋ",           
    "confirm_by_typing_title": "የመሰረዝ ጥያቄን አረጋግጥ"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.dialog_modals = window.locales.ar.dialog_modals || {};window.locales.ar.dialog_modals.dialog_modal_alert = window.locales.ar.dialog_modals.dialog_modal_alert || {};window.locales.ar.dialog_modals.dialog_modal_alert = {...window.locales.ar.dialog_modals.dialog_modal_alert, ...{
    "confirm_action_ok": "حسنا",
    "confirm_action_cancel": "إلغاء",
    "confirm_thanks": "شكرا لإعلامنا.",
    "confirm_delete_with_typing_body": "هل أنت متأكد من رغبتك في حذف <span class=\"thing\">{{thing}} ؟ <br><br> اكتب <strong>{{confirmationText}}</strong> أدناه للتأكيد.",
    "confirm_action_delete": "حذف",
    "confirm_by_typing_title": "تأكيد طلب الحذف"
}
};window.locales.en = window.locales.en || {};window.locales.en.dialog_modals = window.locales.en.dialog_modals || {};window.locales.en.dialog_modals.dialog_modal_alert = window.locales.en.dialog_modals.dialog_modal_alert || {};window.locales.en.dialog_modals.dialog_modal_alert = {...window.locales.en.dialog_modals.dialog_modal_alert, ...{
    "confirm_action_ok": "OK",
    "confirm_action_cancel": "Cancel",
    "confirm_thanks": "Thanks for letting us know.",
    "confirm_delete_with_typing_body": "Are you sure you wish to delete <span class=\"thing\">{{thing}}</span>? <br><br>Type <strong>{{confirmationText}}</strong> below to confirm.",
    "confirm_action_delete": "Delete",
    "confirm_by_typing_title": "Confirm Deletion Request"
}
};window.locales.es = window.locales.es || {};window.locales.es.dialog_modals = window.locales.es.dialog_modals || {};window.locales.es.dialog_modals.dialog_modal_alert = window.locales.es.dialog_modals.dialog_modal_alert || {};window.locales.es.dialog_modals.dialog_modal_alert = {...window.locales.es.dialog_modals.dialog_modal_alert, ...{
    "confirm_action_ok": "DE ACUERDO",
    "confirm_action_cancel": "Cancelar",
    "confirm_thanks": "Gracias por dejarnos saber.",
    "confirm_delete_with_typing_body": "¿Estás seguro de que deseas eliminar <span class=\"thing\">{{thing}} ? <br><br> Escriba <strong>{{confirmationText}}</strong> continuación para confirmar.",
    "confirm_action_delete": "Borrar",
    "confirm_by_typing_title": "Confirmar solicitud de eliminación"
}
};window.locales.it = window.locales.it || {};window.locales.it.dialog_modals = window.locales.it.dialog_modals || {};window.locales.it.dialog_modals.dialog_modal_alert = window.locales.it.dialog_modals.dialog_modal_alert || {};window.locales.it.dialog_modals.dialog_modal_alert = {...window.locales.it.dialog_modals.dialog_modal_alert, ...{
    "confirm_action_ok": "ok",
    "confirm_action_cancel": "Annulla",
    "confirm_thanks": "Grazie per averci fatto sapere.",
    "confirm_delete_with_typing_body": "Sei sicuro di voler eliminare <span class=\"thing\">{{thing}} ? <br><br> Digita <strong>{{confirmationText}}</strong> qui sotto per confermare.",
    "confirm_action_delete": "Elimina",
    "confirm_by_typing_title": "Conferma richiesta di cancellazione"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.dialog_modals = window.locales.zh.dialog_modals || {};window.locales.zh.dialog_modals.dialog_modal_alert = window.locales.zh.dialog_modals.dialog_modal_alert || {};window.locales.zh.dialog_modals.dialog_modal_alert = {...window.locales.zh.dialog_modals.dialog_modal_alert, ...{
    "confirm_action_ok": "好的",           
    "confirm_action_cancel": "取消",           
    "confirm_thanks": "谢谢告知。",           
    "confirm_delete_with_typing_body": "你确定要删除<span class=\"thing\">{{thing}}吗</span>？<br><br>类型<strong>{{confirmationText}}</strong>进行确认。",           
    "confirm_action_delete": "删除",           
    "confirm_by_typing_title": "确认删除请求"           
}
};