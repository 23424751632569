window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.dismissable_message = window.locales.am.dismissable_message || {};window.locales.am.dismissable_message = {...window.locales.am.dismissable_message, ...{
    "close_message": "መልእክት ዝጋ"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.dismissable_message = window.locales.ar.dismissable_message || {};window.locales.ar.dismissable_message = {...window.locales.ar.dismissable_message, ...{
    "close_message": "إغلاق الرسالة"
}
};window.locales.en = window.locales.en || {};window.locales.en.dismissable_message = window.locales.en.dismissable_message || {};window.locales.en.dismissable_message = {...window.locales.en.dismissable_message, ...{
    "close_message": "Close message"
}
};window.locales.es = window.locales.es || {};window.locales.es.dismissable_message = window.locales.es.dismissable_message || {};window.locales.es.dismissable_message = {...window.locales.es.dismissable_message, ...{
    "close_message": "Cerrar mensaje"
}
};window.locales.it = window.locales.it || {};window.locales.it.dismissable_message = window.locales.it.dismissable_message || {};window.locales.it.dismissable_message = {...window.locales.it.dismissable_message, ...{
    "close_message": "Chiudi messaggio"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.dismissable_message = window.locales.zh.dismissable_message || {};window.locales.zh.dismissable_message = {...window.locales.zh.dismissable_message, ...{
    "close_message": "关闭消息"
}
};