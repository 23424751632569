window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.exec_ed_eligibility = window.locales.am.exec_ed_eligibility || {};window.locales.am.exec_ed_eligibility.bundle_box = window.locales.am.exec_ed_eligibility.bundle_box || {};window.locales.am.exec_ed_eligibility.bundle_box = {...window.locales.am.exec_ed_eligibility.bundle_box, ...{
    "new": "አዲስ",
    "exec_ed_programs": "አስፈፃሚ ትምህርት<br/> ፕሮግራሞች",
    "eligible_to_enroll": "በፍላጎትዎ እና በተሞክሮዎ መሰረት፣ ለአዲስ የኳንቲክ ሰርተፊኬቶች ለመመዝገብ ብቁ ነዎት።",
    "ai_and_future_of_business": "AI እና የወደፊት የንግድ ፕሮግራሞች",
    "data_and_finance": "የውሂብ እና የፋይናንስ ፕሮግራሞች",
    "rise_to_c_suite": "ወደ C-Suite ፕሮግራሞች ይነሱ",
    "review_and_register": "ሁሉንም ይገምግሙ እና ይመዝገቡ"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.exec_ed_eligibility = window.locales.ar.exec_ed_eligibility || {};window.locales.ar.exec_ed_eligibility.bundle_box = window.locales.ar.exec_ed_eligibility.bundle_box || {};window.locales.ar.exec_ed_eligibility.bundle_box = {...window.locales.ar.exec_ed_eligibility.bundle_box, ...{
    "new": "جديد",
    "exec_ed_programs": "التعليم التنفيذي<br/> البرامج",
    "eligible_to_enroll": "بناءً على اهتماماتك وخبراتك، أنت مؤهل للتسجيل في شهادات Quantic الجديدة.",
    "ai_and_future_of_business": "الذكاء الاصطناعي ومستقبل برامج الأعمال",
    "data_and_finance": "برامج البيانات والتمويل",
    "rise_to_c_suite": "ارتق إلى برامج C-Suite",
    "review_and_register": "مراجعة الكل والتسجيل"
}
};window.locales.en = window.locales.en || {};window.locales.en.exec_ed_eligibility = window.locales.en.exec_ed_eligibility || {};window.locales.en.exec_ed_eligibility.bundle_box = window.locales.en.exec_ed_eligibility.bundle_box || {};window.locales.en.exec_ed_eligibility.bundle_box = {...window.locales.en.exec_ed_eligibility.bundle_box, ...{
    "new": "NEW",
    "exec_ed_programs": "Executive Education <br/> Programs",
    "eligible_to_enroll": "Based on your interests and experience, you are eligible to enroll in new Quantic certificates.",
    "ai_and_future_of_business": "AI & Future of Business Programs",
    "data_and_finance": "Data & Finance Programs",
    "rise_to_c_suite": "Rise to the C-Suite Programs",
    "review_and_register": "Review All and Register"
}
};window.locales.es = window.locales.es || {};window.locales.es.exec_ed_eligibility = window.locales.es.exec_ed_eligibility || {};window.locales.es.exec_ed_eligibility.bundle_box = window.locales.es.exec_ed_eligibility.bundle_box || {};window.locales.es.exec_ed_eligibility.bundle_box = {...window.locales.es.exec_ed_eligibility.bundle_box, ...{
    "new": "NUEVO",
    "exec_ed_programs": "Educación ejecutiva<br/> Programas",
    "eligible_to_enroll": "Según sus intereses y experiencia, es elegible para inscribirse en nuevos certificados Quantic.",
    "ai_and_future_of_business": "IA y futuro de los programas empresariales",
    "data_and_finance": "Programas de datos y finanzas",
    "rise_to_c_suite": "Ascienda a los programas C-Suite",
    "review_and_register": "Revisar todo y registrarse"
}
};window.locales.it = window.locales.it || {};window.locales.it.exec_ed_eligibility = window.locales.it.exec_ed_eligibility || {};window.locales.it.exec_ed_eligibility.bundle_box = window.locales.it.exec_ed_eligibility.bundle_box || {};window.locales.it.exec_ed_eligibility.bundle_box = {...window.locales.it.exec_ed_eligibility.bundle_box, ...{
    "new": "NUOVO",
    "exec_ed_programs": "Formazione esecutiva<br/> Programmi",
    "eligible_to_enroll": "In base ai tuoi interessi e alla tua esperienza, hai diritto a iscriverti a nuovi certificati Quantic.",
    "ai_and_future_of_business": "Intelligenza artificiale e futuro dei programmi aziendali",
    "data_and_finance": "Programmi dati e finanza",
    "rise_to_c_suite": "Ascesa ai programmi C-Suite",
    "review_and_register": "Rivedi tutto e registrati"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.exec_ed_eligibility = window.locales.zh.exec_ed_eligibility || {};window.locales.zh.exec_ed_eligibility.bundle_box = window.locales.zh.exec_ed_eligibility.bundle_box || {};window.locales.zh.exec_ed_eligibility.bundle_box = {...window.locales.zh.exec_ed_eligibility.bundle_box, ...{
    "new": "新的",
    "exec_ed_programs": "高管教育<br/>程式",
    "eligible_to_enroll": "根据您的兴趣和经验，您有资格注册新的 Quantic 证书。",
    "ai_and_future_of_business": "人工智能与商业未来计划",
    "data_and_finance": "数据与金融项目",
    "rise_to_c_suite": "晋升最高管理层计划",
    "review_and_register": "查看全部并注册"
}
};